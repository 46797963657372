.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.vh-100 {
  min-height: 100vh;
}

.header-text {
  font-size: 2.5rem;
  text-align: left;
}

@media (max-width: 991px) {
  .header-text {
    text-align: center;
  }
}

.header-content {
  z-index: 2;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.lead-normal {
  font-size: 1.15rem;
}

@media (max-width: 1199px) {
  .lead-normal {
    font-size: 1rem;
  }

  .header-text {
    font-size: 2.25em;
  }
}

@media (max-width: 767px) {
  .header-text {
    font-size: 2em;
  }
}

.lead-lg {
  font-size: 1.4rem;
  font-weight: 400;
}

@media (max-width: 425px) {
  .header-text {
    font-size: 3em;
  }

  .lead-lg {
    font-size: 1.25em;
  }
}

.sessions-text {
  font-size: 2.5em;
  max-width: 850px;
  font-weight: 300 !important;
}

.medium-text {
  font-size: 1.1em;
  font-weight: 300;
}

.small-text {
  font-size: 1em;
  font-weight: 300;
}

/* Centering links and adding active link styles */
.navbar-custom {
  transition: background-color 0.3s ease;
}

.navbar-custom.hidden {
  transform: translateY(-100%);
  transition: transform 0.3s ease;
}

.navbar-custom.bg-theme-light {
  background-color: rgba(234, 221, 202, 0.1);
}

.navbar-custom.bg-white {
  background-color: #fff;
}

@media (max-width: 1399px) {
  .navbar-collapse {
    transition: height 0.2s ease, opacity 0.2s ease;
    height: 0;
    opacity: 0;
    overflow: hidden;
  }
}

.navbar-transparent {
  background-color: transparent !important;
  position: absolute !important;
  width: 100%;
  z-index: 1000;
  color: #fff !important;
}

.navbar-collapse.show {
  display: flex;
  position: absolute;
  padding: 1.5em 1em;
  background: rgb(44, 83, 101);
  z-index: 1000;
  text-align: center;
  font-size: 1.25em;
  color: #fff !important;
  width: 100vw;
  height: 100vh;
  opacity: 1;
  top: 0;
  left: 0;
}

.bars, .exit {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease, display 0.3s ease;
  display: none;
}

.bars.show, .exit.show {
  opacity: 1;
  visibility: visible;
  display: block;
}

.collapsed,
.navbar-toggler {
  border: none !important;
  outline: none !important;
  z-index: 1001;
}

.collapsed:focus,
.navbar-toggler:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.navbar-nav {
  width: 100%;
  display: flex;
  justify-content: end;
}

.navbar-nav .nav-link {
  margin: 0 10px;
  color: #fff !important;
}

.navbar-nav .nav-link:hover {
  color: #d4af37 !important;
}

.navbar-nav .nav-link.active {
  font-weight: bold;
  color: #d4af37 !important;
}

@media (max-width: 767px) {
  .navbar-nav .nav-link, .nav-btn {
    text-align: center;
  }
}

/* Smooth transition for navbar hiding */
.hidden {
  transform: translateY(-100%);
  transition: transform 0.3s ease-in-out;
}

.header-section {
  position: relative;
  min-height: 100vh;
}

@media (max-width: 1720px) {
  .header-section {
    min-height: 100vh;
  }
}

@media (max-width: 1399px) {
  .header-section {
    min-height: 110vh;
  }
}

.header-img {
  width: 100%;
  max-width: 450px !important;
}

.header-kylie-image {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50%;
  max-width: 625px !important;
  z-index: 0;
}

.header-kylie-image-small {
  display: none;
  object-fit: cover;
}

@media (max-width: 1199px) {
  .header-kylie-image {
    width: 50%;
    max-width: 550px !important;
  }
}

@media (max-width: 991px) {
  .header-kylie-image {
    display: none;
  }

  .header-kylie-image-small {
    display: block;
    position: relative;
    width: 100%;
    max-width: 450px !important;
    z-index: 0;
    bottom: 0;
    margin: 0 auto;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
  }

  .header-content {
    margin-top: 80px;
  }
}

@media (max-width: 450px) {
  .header-kylie-image-small {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.quote-section {
  position: relative;
}

.quote-plate-image {
  position: absolute;
  top: -80%;
  right: -15%;
  width: 50%;
  max-width: 850px !important;
  z-index: -1;
}

.quote-text {
  font-size: 3.5em;
  font-weight: 500;
  color: #45788E;
}

@media (max-width: 1199px) {
  .quote-plate-image {
    top: -60%;
  }
}

@media (max-width: 991px) {
  .quote-plate-image {
    top: -40%;
  }
}

@media (max-width: 767px) {
  .quote-text {
    padding-top: 5%;
  }

  .quote-plate-image {
    top: -20%;
  }
}

@media (max-width: 424px) {
  .quote-text {
    padding-top: 30%;
    font-size: 2.5em;
  }

  .quote-plate-image {
    top: -20%;
    width: 75%;
  }
}

@media (max-width: 374px) {
  .quote-text {
    font-size: 2.5em;
    padding-top: 40%;
  }

  .quote-plate-image {
    top: -10%;
    width: 75%;
  }
}

@media (max-width: 319px) {
  .quote-text {
    padding-top: 40%;
  }

  .quote-plate-image {
    top: -15%;
    right: -25%;
    width: 100%;
  }
}

.techniques-text {
  max-width: 900px;
}

.me-section {
  position: relative;
  z-index: 1;
}

.top-contact-list {
  padding: 0 10px;
  list-style: none;
}

.top-contact-list li {
  margin-bottom: 5px;
}

.top-contact-list a,
.top-contact-list button {
  color: #000;
  text-decoration: none;
  transition: 0.3s ease;
}

.top-contact-list a:hover,
.top-contact-list button:hover {
  color: #d4af37;
  text-decoration: underline;
}

.contact-lead {
  font-size: 1.5em;
  padding-bottom: 0;
  margin-bottom: 0.25em;
}

.footer-link {
  background: none;
  border: none;
  font: inherit;
  color: inherit;
  outline: none;
}

.gold-theme-color {
  color: #d4af37;
}

.green-theme-color {
  color: #8D9B6A;
}

.bg-theme-light {
  background-size: cover;
  /* background-color: rgba(234, 221, 202, 0.5) !important; */
  background-color: #e8dfe037 !important;
}

.bg-blue-theme {
  background-size: cover;
  background-color: #45788E !important;
}

.bg-home {
  background-size: cover;
  background: rgb(44, 83, 101);
  background: linear-gradient(90deg, rgba(44, 83, 101, 1) 34%, rgba(69, 120, 142, 1) 74%, rgba(255, 255, 255, 0) 100%);
  /* background: linear-gradient(90deg, rgba(69,120,142,1) 0%, rgba(255,255,255,0) 100%);
  background: linear-gradient(90deg, rgba(44,83,101,1) 41%, rgba(255,255,255,0) 100%); */
}

@media (max-width: 991px) {
  .bg-home {
    background: rgb(44, 83, 101);
    /* background: linear-gradient(180deg, rgba(44, 83, 101, 1) 34%, rgba(69, 120, 142, 1) 74%, rgba(255, 255, 255, 0) 100%); */
  }
}

.bg-theme {
  background-size: cover;
  background-color: #FAEBD7 !important;
}

.bg-theme-dark {
  background-color: #DAB692 !important;
}

.bg-theme-footer {
  background-size: cover;
  /* background-color: rgba(234, 221, 202, 0.3) !important; */
  background-color: #e8dfe037 !important;
}

.btn-theme {
  color: #d4af37 !important;
  background: transparent;
  border: 2px solid #d4af37 !important;
  transition: 0.3s ease;
}

.btn-theme:hover {
  background: #d4af37 !important;
  color: #fff !important;
}

.btn-theme-solid {
  color: #fff !important;
  background: #d4af37 !important;
  border: 2px solid #d4af37 !important;
  transition: 0.3s ease;
}

.btn-theme-solid:hover {
  background: #b18f20 !important;
  border: 2px solid #b18f20 !important;
  color: #fff !important;
}

.section-large {
  min-height: 95vh;
}

.section-xl {
  min-height: 98vh;
}

.section-medium {
  min-height: 70vh;
}

.nav-temp {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background-color: rgba(234, 221, 202, 0.1);
}

.nav-logo {
  max-width: 400px;
  cursor: pointer;
}

.navbar-brand {
  z-index: 1001;
}

@media (max-width: 767px) {
  .nav-logo {
    max-width: 175px;
  }
}

.footer-logo {
  width: 100%;
  max-width: 300px;
}

@media (max-width: 767px) {
  .nav-logo {
    max-width: 200px;
  }
}

.hidden {
  display: hidden;
}

.section-subtitle {
  font-size: 1.25em;
  font-weight: 600;
}

.section-title,
.section-subtitle,
.service-card-title,
.section-title-small,
.section-title-smaller {
  color: #8D9B6A;
}

.section-title-smaller {
  font-size: 1.25em;
}

.review-card-title {
  font-size: 1.5rem;
  font-weight: 400;
}

.review-card {
  border-radius: 5px;
  width: 100%;
  padding: 1.5em;
  height: 340px;
  background-color: white;
  display: flex;
  align-items: center;
}

.review-circle-item {
  display: flex;
  justify-content: center;
  margin-right: 10px;
}

.review-author {
  display: block;
  text-align: center;
}

@media (max-width: 1399px) {
  .review-card {
    height: 380px;
  }
}

@media (max-width: 991px) {
  .review-card {
    height: 300px;
  }
}

@media (max-width: 767px) {
  .review-card {
    height: 400px;
  }
}

@media (max-width: 449px) {
  .review-card {
    height: auto;
  }
}

@media (min-width: 320px) {
  .review-author {
    display: flex;
    text-align: left;
  }
}

.review-circle {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #4E9B7C;
  /* Circle color */
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.review-circle-blue {
  background-color: #2C5365;
}

.review-circle-yellow {
  background-color: #d4af37;
}

.review-author-name {
  font-size: 1.25em;
  font-weight: 500;
}

.rating-stars {
  color: #f0ad4e;
}

.circle-icon {
  color: #fff;
  font-size: 40px;
}

.review-card-icon {
  font-size: 36px;
  color: #8D9B6A;
  margin-bottom: 10px;
}

.review-card-content {
  font-size: 1em;
  font-weight: 300;
}

.service-card-title {
  font-size: 1.5rem;
  font-weight: 400;
}

.service-card {
  border-radius: 5px;
  width: 100%;
  padding: 1.5em;
  height: 320px;
  background-color: white;
  text-align: center;
}

@media (max-width: 767px) {
  .service-card {
    height: auto;
  }
}

.service-card-icon {
  font-size: 36px;
  color: #8D9B6A;
  margin-bottom: 10px;
}

.service-card-content {
  font-size: 1em;
  font-weight: 300;
}

.service-img {
  width: 350px;
  height: 350px;
  aspect-ratio: 1/1;
  object-fit: cover;
  border-radius: 5px;
}

@media (max-width: 767px) {
  .service-img {
    width: 100%;
    max-height: 350px;
  }
}

.about-img-small {
  width: 150px;
}

.relationship-image {
  height: 400px;
  width: 100%;
  background: url(./img/relationship.jpeg) center/cover no-repeat;
  clip-path: url(#diagonal-ellipse-reverse);
}

.individual-image {
  height: 400px;
  width: 100%;
  background: url(./img/individual-2.jpg) center/cover no-repeat;
  clip-path: url(#diagonal-ellipse-reverse);
}

.about-image {
  height: 100%;
  width: 100%;
  background: url(./img/photo2.jpg) center/cover no-repeat;
}

.about-image-small {
  display: none;
}

.faq-image {
  height: 100%;
  width: 100%;
  background: url(./img/counselling-img.jpg) center/cover no-repeat;
}

.faq-image-small {
  display: none;
}

@media (max-width: 767px) {
  .about-image, .faq-image {
    display: none;
  }

  .about-image-small, .faq-image-small {
    display: block;
    object-fit: cover;
    position: relative;
    width: 100%;
    min-height: 600px;
    max-height: 650px;
    z-index: 0;
    bottom: 0;
    margin: 0 auto;
  }
}

.plate-image {
  height: 400px;
  width: 100%;
  background: url(./img/kintsugi-plate.png) center/contain no-repeat;
}

.bowl-image {
  height: 350px;
  width: 100%;
  background: url(./img/kintsugi-660x350.png) center/contain no-repeat;
}

.kylie-image {
  height: 350px;
  width: 350px;
  background: url(./img/kylie.jpg) center/cover no-repeat;
  clip-path: circle(50% at 50% 50%);
}

.card-img-top {
  height: 225px;
  object-fit: cover;
  object-position: center;
}

@media (max-width: 991px) {
  .kylie-image {
    height: 250px;
    width: 250px;
  }
}

.shadow-dark {
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.4);
}

.form-container {
  width: 100%;
  /* max-width: 500px;
  padding: 30px 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
}

.form-required {
  text-decoration: underline dotted;
}

.form-title {
  font-size: 24px;
  margin-bottom: 10px;
  color: #8D9B6A;
}

.form-subtitle {
  font-size: 16px;
  margin-bottom: 20px;
  color: #000;
}

.form-group {
  margin-bottom: 1.5em;
}

.form-control {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.form-control:focus {
  border-color: #8D9B6A;
  outline: none !important;
  box-shadow: none !important;
  caret-color: #d4af37 !important;
}

.appointment-image {
  width: 100%;
  max-width: 550px;
  object-fit: cover;
}

.bottom-image {
  aspect-ratio: 1/1;
  object-fit: cover;
  border: 1px solid #dddddd;
}

.btn-theme-green-solid-form {
  color: #fff !important;
  background: #8D9B6A !important;
  border: 2px solid #8D9B6A !important;
  transition: 0.3s ease;
  white-space: nowrap;
  text-align: center;
  width: 100%;
}

.btn-theme-green-solid-form:hover {
  background: #5b6442 !important;
  border-color: #5b6442 !important;
}

.btn-theme-green {
  color: #8D9B6A !important;
  background: transparent;
  border: 2px solid #8D9B6A !important;
  transition: 0.3s ease;
  white-space: nowrap;
}

.btn-theme-green:hover {
  background: #8D9B6A !important;
  color: #fff !important;
}

.btn-theme-green-solid {
  color: #fff !important;
  background: #8D9B6A !important;
  border: 2px solid #8D9B6A !important;
  transition: 0.3s ease;
  white-space: nowrap;
}

.btn-theme-green-solid:hover {
  background: #5b6442 !important;
  border-color: #5b6442 !important;
}

.show-btn {
  background: none;
  border: none;
  outline: none;
  transition: 0.3s ease;
}

.show-btn:hover {
  color: #8D9B6A !important;
}

textarea {
  resize: none !important;
}

.hyphen-list {
  list-style-type: '-  ';
}

.slide-enter {
  max-height: 0;
  overflow: hidden;
  transition: max-height 600ms ease;
}

.slide-enter-active {
  max-height: 200px;
}

.slide-exit {
  max-height: 200px;
  overflow: hidden;
  transition: max-height 600ms ease;
}

.slide-exit-active {
  max-height: 0;
}

.qualifications-container {
  display: inline-block;
}

.wave {
  position: absolute;
  width: 100%;
  height: auto;
}

.wave-top {
  top: 0;
  left: 0;
  transform: translateY(-100%);
}

.wave-bottom {
  bottom: 0;
  left: 0;
  transform: rotate(180deg) translateY(-100%);
}

.banner {
  background-color: #8D9B6A !important;
}

/* Remove background color and border */
.accordion {
  background-color: transparent !important;
  border: none !important;
}

.accordion-item {
  border: none !important;
  background-color: transparent !important;
}

.accordion-header {
  background-color: transparent !important;
}

.accordion-header {
  position: relative;
}

.accordion-header::after {
  content: "";
  display: block;
  width: 100%;
  height: 1.5px;
  background-color: black;
  position: absolute;
  bottom: 0;
  left: 0;
}

.accordion-button {
  background-color: transparent !important;
  border: none !important;
}

.accordion-button:focus {
  border: none !important;
  box-shadow: none !important;
}

.accordion-body {
  background-color: #fff !important;
  border-bottom: 1px solid #000;
}