body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  overflow-x: hidden;
}

code {
  font-family: "Poppins", sans-serif;
}

#root {
  font-family: "Poppins", sans-serif;
    max-width: 100%;
    overflow-x: hidden;
}