.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.85);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.loader-image {
    width: 100px;
    height: 100px;
    animation: loaderAnimation 2s ease-in-out infinite;
}

@keyframes loaderAnimation {
    0%, 50%, 100% {
        transform: scale(1);
    }

    30% {
        transform: scale(1.2);
    }

    70% {
        transform: scale(1.4);
    }
}
